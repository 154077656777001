const getBranchesAndStatesRequest = (state) => {
  state.branchesLoaded = false
}

const getBranchesAndStatesSuccess = (state, payload) => {
  const { branches, states } = payload

  state.branches = branches
  state.states = states
  state.branchesLoaded = true
}

const setBranchEmployees = (state, { branch, employees }) => {
  state.branchEmployees = {
    ...state.branchEmployees,
    [branch.branchId]: [...employees]
  }
  state.branchDetails = {
    ...state.branchDetails,
    [branch.branchId]: branch
  }
}

const setGroups = (state, payload) => {
  state.groups = payload
  state.groupsLoaded = true
}

const setCurrentGroup = (state, payload) => {
  const { groupMembers, groupSummary: { groupTitle, groupDescription } } = payload
  state.currentGroup = {
    title: groupTitle,
    description: groupDescription,
    userList: groupMembers
  }
}

const resetCurrentGroup = (state) => {
  state.currentGroup = {
    title: '',
    description: '',
    userList: []
  }
}

export default {
  getBranchesAndStatesRequest,
  getBranchesAndStatesSuccess,
  setBranchEmployees,
  setGroups,
  setCurrentGroup,
  resetCurrentGroup
}
